import AxiosInstance from "../utils/AxiosInstance";
import { Prompt } from "../models/prompt";
export const promptGet = async (journal_id: string, prompt_id: string) => {
  const axios = AxiosInstance;
  const prompts: Prompt[] = await axios
    .get(`/api/journals/${journal_id}/suggested-prompts/`)
    .then(function (response) {
      return response.data;
    });
  for (const prompt of prompts) {
    if (prompt.public_id === prompt_id) {
      return prompt;
    }
  }
};
