import { useState } from "react";
import { Prompt } from "../models/prompt";
import { Thought } from "../models/thought";
import MediaView from "./Media";
import "./../index.css";
import { TiDeleteOutline } from "react-icons/ti";

interface Props {
  thought: Thought;
  editable: boolean;
  saveThought: (thought: Thought) => void;
  updateMedia: (media: string | null, thought: Thought) => void;
  deleteThoughtHandler: (thought: Thought) => void;
  prompt: Prompt;
}

function ThoughtView({ thought, editable, saveThought, updateMedia, deleteThoughtHandler, prompt }: Props) {
  const [textareaLength, setTextareaLength] = useState<number>(64);

  const onThoughtChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaLength(Math.max(64, e.target.scrollHeight));
    saveThought({...thought, text: e.target.value});
  };

  return (
    <div className="my-0.5">
      {editable && <TiDeleteOutline className="absolute right-0" size={32} color="red" onClick={() => deleteThoughtHandler(thought)}  />}
      <p className="retroText">{prompt.prompt_text}</p>
      <div className="flex flex-row">
        <div className="w-1/2 p-4 hover:w-full transition-all duration-500">
          <textarea
            disabled={!editable}
            onChange={(e) => onThoughtChange(e)}
            className="solitreo border-solid resize-y w-full h-64 rounded-md border-2"
            defaultValue={thought.text}
          ></textarea>
        </div>
        <div className="w-1/2 p-4 hover:w-full transition-all duration-500">
          <MediaView thought={thought} editable={editable} updateMedia={updateMedia}/>
        </div>
      </div>
    </div>
  );
}

export default ThoughtView;
