import getJournal from "../api_functions/journalGet";
import { promptGet } from "../api_functions/promptGet";

import { Thought } from "../models/thought";
import { exportPDF } from "./exportPDF";

export const downloadPDF = async (journalID: string) => {
  const journal = await getJournal(journalID);
  const journalThoughts: Thought[] = journal.thoughts;
  let journalText: string[] = [];
  for (let thought of journalThoughts) {
    const prompt = await promptGet(journalID, thought.prompt);
    const fullText = prompt?.prompt_text + " " + thought.text;
    journalText.push(fullText);
  }
  const title = `Your journal entry for ${journal.date
    .toString()
    .substring(0, 15)}`;
  exportPDF(title, journalText);
};
