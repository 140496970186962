import { IFormInput } from "./FormInput";
import FormCheckboxInputNormal from "./FormCheckboxInputNormal";
import FormCheckboxInputError from "./FormCheckboxInputError";

export type IFormCheckboxInput = IFormInput;

function FormCheckboxInput(props: IFormCheckboxInput) {
  return (
    <div className={props.className}>
      {props.valid ? (
        <FormCheckboxInputNormal {...props} />
      ) : (
        <FormCheckboxInputError {...props} />
      )}
    </div>
  );
}

export default FormCheckboxInput;
