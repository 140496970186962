export interface HomeLinkInterface {
  link: string;
  description: string;
  className: string;
}

export default function HomeLink(props: HomeLinkInterface) {
  const givenLink = props.link;
  const description = props.description;
  return (
    <a href={givenLink}>
      <div
        className={`${props.className}  w-48 h-24 bg-purple-200 border inline-flex items-center  hover:bg-purple-300`}
      >
        <p className="w-full text-center">
          <a>{description}</a>
        </p>
      </div>
    </a>
  );
}
