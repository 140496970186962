import HomeLink from "./HomeLink";

export default function HomeLinks() {
  return (
    <div className="text-center flex justify-center">
      <div>
        <HomeLink
          link="/journal"
          description="Browse Your Journal"
          className=""
        />
        <HomeLink
          link="#"
          description="Customise Your Mascot - Coming Soon ;)"
          className="mt-5"
        />
      </div>
      <div>
        <HomeLink
          link="#"
          description="View the Calendar - Coming Soon ;)"
          className=""
        />

        <HomeLink link="/settings" description="Settings" className="mt-5" />
      </div>
    </div>
  );
}
