import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { IFormTextInput } from "./FormTextInput";
import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export interface IFormTextInputError extends IFormTextInput {
  errorMessage: string;
}

export default function FormTextInputError(props: IFormTextInputError) {
  const [type, setType] = useState(props.type);
  const originalType = props.type;
  return (
    <div className={props.className}>
      <div className="flex items-center w-full justify-between">
        <label
          htmlFor={props.title}
          className=" retroText block text-sm font-medium text-gray-700"
        >
          {props.title}
        </label>
        <div>
          {originalType === "password" &&
            (type === "password" ? (
              <AiOutlineEye onClick={() => setType("text")} />
            ) : (
              <AiOutlineEyeInvisible onClick={() => setType("password")} />
            ))}
        </div>
      </div>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type={type}
          name={props.name}
          id={props.id}
          value={props.value}
          className={
            " solitreo block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300  "
          }
          placeholder={props.placeholder}
          onChange={props.onChange}
          autoComplete={props.autoComplete}
          aria-invalid={props["aria-invalid"]}
          aria-describedby={props["aria-describedby"]}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-200"
            aria-hidden="true"
          />
        </div>
      </div>
      <p
        className="mt-2 retroText text-sm text-red-400"
        id={props["aria-describedby"]}
      >
        {props.errorMessage}
      </p>
    </div>
  );
}
