import { Prompt } from "../models/prompt";
import AxiosInstance from "../utils/AxiosInstance";

export default async function PromptsGet(journal_id: string): Promise<Prompt[]> {
  const axios = AxiosInstance;

  return axios
    .get(`api/journals/${journal_id}/suggested-prompts/`, {})
    .then(function ({ data }) {
      return data;
    });
}
