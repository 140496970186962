const PrivacyPolicyPage = () => {
  return (
    <div className="max-w-2xl mb-14 mt-4 mx-auto  bg-yellow-100 border-8 p-8 border-pink-100">
      <h1 className="text-xl underline retroText">
        Knuthleheads Privacy Policy
      </h1>
      <p className="mt-2 solitreo">
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you visit habithatch.knuthleheads.uk
        (the &ldquo;Site&rdquo;).
      </p>
      <p className="mt-4 retroText">PERSONAL INFORMATION WE COLLECT</p>
      <p className="mt-2 solitreo">
        When you visit the Site, we automatically collect certain information
        about your device, including information about your web browser, IP
        address, time zone, and some of the cookies that are installed on your
        device.
      </p>
      <p className="solitreo">
        We collect Device Information using the following technologies:
      </p>
      <p className="solitreo">
        - &ldquo;Cookies&rdquo; are data files that are placed on your device or
        computer and often include an anonymous unique identifier. For more
        information about cookies, and how to disable cookies, visit
        http://www.allaboutcookies.org.
      </p>
      <p className="solitreo">
        When we talk about &ldquo;Personal Information&rdquo; in this Privacy
        Policy, we are talking both about Device Information and Order
        Information.
      </p>
      <p className="mt-4 retroText">HOW DO WE USE YOUR PERSONAL INFORMATION?</p>
      <p className="mt-2 solitreo">
        We use the Order Information that we collect generally to fulfill any
        orders placed through the Site (including processing your payment
        information, arranging for shipping, and providing you with invoices
        and/or order confirmations). Additionally, we use this Order Information
        to:
        <br />
        Communicate with you;
        <br />
        When in line with the preferences you have shared with us, provide you
        with information or advertising relating to our products or services.
      </p>

      <p className="mt-4 retroText">
        DO NOT TRACK
        <p className="mt-2 solitreo">
          Please note that we do not alter our Site&rsquo;s data collection and
          use practices when we see a Do Not Track signal from your browser.
        </p>
      </p>

      <p className="mt-4 retroText">
        YOUR RIGHTS
        <p className="mt-2 solitreo">
          If you are a European resident, you have the right to access personal
          information we hold about you and to ask that your personal
          information be corrected, updated, or deleted. If you would like to
          exercise this right, please contact us through the contact information
          below. Additionally, if you are a European resident we note that we
          are processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
          Additionally, please note that your information will be transferred
          outside of Europe, including to Canada and the United States.
        </p>
      </p>

      <p className="mt-4 retroText">
        MINORS
        <p className="mt-2 solitreo">
          The Site is not intended for individuals under the age of 15.
        </p>
      </p>
      <p className="mt-4 retroText">
        CHANGES
        <p className="mt-2 solitreo">
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>
        <p className="mt-4 retroText">CONTACT US</p>
        <p className="mt-2 solitreo">
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at{" "}
          <a
            className="text-blue-800 underline"
            href="mailto:support.habithatch.knuthleheads.uk"
          >
            support.habithatch.knuthleheads.uk
          </a>{" "}
          or by mail using the details provided below:
        </p>
      </p>
      <p className="solitreo">
        Bush House, Strand, WC2B 4BG, London, United Kingdom
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
