import { IFormCheckboxInput } from "./FormCheckboxInput";

export interface IFormCheckboxInputError extends IFormCheckboxInput {}
function FormCheckboxInputError(props: IFormCheckboxInputError) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          name={props.name}
          type="checkbox"
          checked={props.checked}
          onChange={props.onChange}
          className="h-4 w-4  border-gray-300 text-red-300 "
        />
      </div>
      <div className="ml-3 text-sm">
        <label
          htmlFor="comments"
          className="font-medium retroText text-red-400"
        >
          {props.title}
        </label>
        <p className="solitreo text-red-400">{props.errorMessage}</p>
      </div>
    </div>
  );
}

export default FormCheckboxInputError;
