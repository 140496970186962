import { useState } from "react";
import { Thought } from "../models/thought";
import { DEFAULT_IMAGE_URL } from "../utils/constants";
import { getImageType } from "../utils/getImageType";

interface MediaProps {
    thought: Thought;
    editable: boolean;
    updateMedia: (media: string | null, thought: Thought) => void;
}

interface ButtonProps {
    thought: Thought;
    updateMedia: (media: string | null, thought: Thought) => void;
}

function ImageButton({ thought, updateMedia }: ButtonProps) {
    function setNewImageFromPicker() {
        const input = document.getElementById(thought.public_id + "imageInput") as HTMLInputElement;
        const file = input.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64 = reader.result?.toString().split(",")?.[1];
                if (base64) {
                    updateMedia(base64, thought);
                }
            };
        }
    }

    const id = thought.public_id + "imageInput";

    return (
        <div className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-1 rounded">
            <label htmlFor={id} >Set an Image</label>
            <input id={id} type="file" onChange={setNewImageFromPicker} accept="image/*" className="hidden" />
        </div>
    )
}

// This is the component that renders the image
function MediaView({ thought, editable, updateMedia }: MediaProps) {

    const [image, setImage] = useState<string | null>(thought.media);

    function update(newImage: string | null, t: Thought) {
        updateMedia(newImage, t);
        setImage(newImage);
    }

    function clearImage() {
        update(null, thought);
    }

    let image_element;

    if (image) {
        const imageType = getImageType(image);
        const imageData = "data:image/" + imageType + ";charset=utf-8;base64," + image;

        image_element = <img src={imageData} className="absolute inset-0 object-cover w-full h-full" title="User uploaded image" alt="User uploaded image" />;


    } else {
        // first few letters of public_id
        const unique_slug = thought.public_id.substring(0, 3);

        image_element = <img src={DEFAULT_IMAGE_URL + "&img=" + unique_slug} className="absolute inset-0 object-cover w-full h-full" title="Placeholder image" alt="Placeholder image" />;
    };

    if (editable) {
        return (
            <div className="border rounded-md border-2 border-black min-h-0 h-full w-full relative overflow-hidden">
                {image_element}
                <div className="absolute p-4 top-0 left-0 w-full h-full p-4 text-white bg-black bg-opacity-0 hover:bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                    <ImageButton thought={thought} updateMedia={update} />
                    {
                        image ? <button onClick={clearImage} className="bg-red-500 hover:bg-red-700 text-white font-bold p-1 rounded">Remove Image</button> : null
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className="border-solid rounded-md border-2 h-full w-full relative overflow-hidden">
                {image_element}
            </div>
        )
    }
}

export default MediaView;
