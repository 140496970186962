import { ChangeEvent, useState } from "react";
import { Form, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import FormTextInput from "../components/FormTextInput";
import HomeMascot from "../components/HomeMascot";
import MascotLabeled from "../components/MascotLabeled";
import PopupModal from "../components/PopupModal";
import { useAuth } from "../utils/useAuth";

const LoginPage = () => {
  const auth = useAuth();
  const nav = useNavigate();
  const [showLoginError, setShowLoginError] = useState(false);
  const toggleShowLoginError = () => {
    setShowLoginError(!showLoginError);
  };

  const [formIndex, setFormIndex] = useState(0);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  function incrementFormIndex() {
    setFormIndex(formIndex + 1);
  }

  function decrementFormIndex() {
    setFormIndex(formIndex - 1);
  }
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  }
  const handleLoginFailure = () => {
    toggleShowLoginError();
    setFormIndex(0);
    setFormData({
      username: "",
      password: "",
    });
  };

  const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formIndex < 2) {
      incrementFormIndex();
    } else {
      const status = await auth?.signIn(formData.username, formData.password);
      if (status === 200) {
        nav("/home");
      } else {
        handleLoginFailure();
      }
    }
  };

  return (
    <div className="max-w-md mx-auto mt-24 p-8 border-8 bg-yellow-100 border-pink-100">
      <h1 className="text-3xl text-center retroText mb-6">Log in</h1>
      <Form method={"post"} onSubmit={handleSubmit} className="">

      {formIndex == 0 && <MascotLabeled width={100} height={100} mt={"mt-6"} label="Can you provide me your username?"/>}
      {formIndex == 1 && <MascotLabeled width={100} height={100} mt={"mt-6"} label="Can you provide me your password?"/>}

        <FormTextInput
          type="text"
          name="username"
          id="username"
          errorMessage="You have to tell us your username in order to log in."
          value={formData.username}
          valid={true}
          onChange={handleChange}
          className={formIndex !== 0 ? "invisible absolute" : "block mt-3"}
          placeholder="Enter Username"
          autoComplete="username"
        />

        <FormTextInput
          type="password"
          name="password"
          id="password"
          title="Password"
          errorMessage="You have to put in your password in order to log in."
          valid={true}
          onChange={handleChange}
          value={formData.password}
          className={formIndex !== 1 ? "invisible absolute" : "block mt-3"}
          placeholder="Enter password"
          autoComplete="password"
        />

        <Button
          type="submit"
          className={formIndex !== 2 ? "invisible absolute" : "text-center"}
          width={6}
          height={3}
          buttonType={"primary"}
          buttonText={"Log in"}
        />
      </Form>
      <div className="flex mt-6 justify-center">
        {formIndex > 0 && (
          <Button
            className="mx-4"
            onClick={decrementFormIndex}
            width={6}
            height={3}
            buttonType={"secondary"}
            buttonText={"back"}
          />
        )}
        <br />
        {formIndex < 2 && (
          <Button
            className="mx-4"
            onClick={incrementFormIndex}
            width={6}
            height={3}
            buttonType={"primary"}
            buttonText={"next "}
          />
        )}
      </div>
      <PopupModal
        isOpen={showLoginError}
        onClose={toggleShowLoginError}
        children={
          <div className="retroText text-center">
            It looks like either your username or password was incorrect. Please
            try again.
          </div>
        }
      />
    </div>
  );
};

export default LoginPage;
