import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import router from "./App";
import axios from "axios";
import { RouterProvider } from "react-router-dom";
import AuthProvider from "./utils/AuthProvider";
import { config } from "./Config";

axios.defaults.baseURL = config.urls.API_BASE_URL;

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);
