import { createBrowserRouter } from "react-router-dom";
import HomePage from "./page/HomePage";
import SignUpPage from "./page/SignUpPage";
import PrivateRoute from "./utils/PrivateRoute";
import LoginPage from "./page/LoginPage";
import SettingsPage from "./page/SettingsPage";
import Journal from "./page/Journal";
import CookiesPolicyPage from "./page/CookiesPolicyPage";
import PublicRoute from "./utils/PublicRoute";
import Base from "./components/Base";
import EmailVerificationPage from "./page/EmailVerificationPage";
import { loadCode } from "./page/VerifyWithCode";
import VerifyWithCode from "./page/VerifyWithCode";

import DownloadJournalsPage from "./page/DownloadJournalsPage";
import NotFound from "./page/NotFound";
import RootPage from "./page/RootPage";
import TeamInfo from "./page/TeamInfo";
import PrivacyPolicyPage from "./page/PrivacyPolicyPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Base />,
    children: [
      {
        path: "home",
        element: <PrivateRoute outlet={<HomePage />} />,
      },
      {
        path: "",
        element: <PublicRoute outlet={<RootPage />} />,
      },
      {
        path: "signup",
        element: <PublicRoute outlet={<SignUpPage />} />,
      },
      {
        path: "login",
        element: <PublicRoute outlet={<LoginPage />} />,
      },
      {
        path: "settings",
        element: <PrivateRoute outlet={<SettingsPage />} />,
      },
      {
        path: "home",
        element: <PrivateRoute outlet={<HomePage />} />,
      },
      {
        path: "journal",
        element: <PrivateRoute outlet={<Journal />} />,
      },
      {
        path: "cookiespolicy",
        element: <CookiesPolicyPage />,
      },
      { path: "privacypolicy", element: <PrivacyPolicyPage /> },
      {
        path: "/verifyemail",
        element: <EmailVerificationPage />,
      },
      {
        path: "/verifyaccount/:username/:token",
        element: <VerifyWithCode />,
        loader: loadCode,
      },
      {
        path: "/downloadjournals",
        element: <PrivateRoute outlet={<DownloadJournalsPage />} />,
      },
      { path: "teaminformation", element: <TeamInfo /> },

      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);
export default router;
