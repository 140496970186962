import { ChangeEvent, FormEvent, useState } from "react";
import newPasswordPost from "../api_functions/newPasswordPost";
import {
  passwordValidator,
  passwordConfirmationValidator,
} from "../utils/FormValidators";
import Button from "./Button";
import FormTextInput from "./FormTextInput";
import PopupModal from "./PopupModal";

const UpdatePasswordForm = () => {
  const [showUpdateSuccess, setShowUpdateSuccess] = useState<boolean>(false);
  const [showUpdateFailure, setShowUpdateFailure] = useState(false);

  const toggleShowUpdateSuccess = () => {
    setShowUpdateSuccess(!showUpdateSuccess);
  };
  const toggleShowUpdateFailure = () => {
    setShowUpdateFailure(!showUpdateFailure);
  };
  const [formData, setFormData] = useState<{
    [key: string]: any;
  }>({
    oldPassword: {
      value: "",
      valid: true,
      validator: passwordValidator,
    },
    password: {
      value: "",
      valid: true,
      validator: passwordValidator,
    },
    passwordConfirmation: {
      value: "",
      valid: true,
      validator: passwordConfirmationValidator,
    },
  });

  async function changeValidity() {
    const updatedFormData: { [key: string]: any } = {};
    for (const key in formData) {
      if (key === "passwordConfirmation") {
        const field = formData[key];
        updatedFormData[key] = {
          ...field,
          valid: field.validator(field.value, formData.password.value),
        };
      } else {
        const field = formData[key];
        updatedFormData[key] = {
          ...field,
          valid: await field.validator(field.value),
        };
      }
    }

    setFormData(updatedFormData);
  }

  const handlePasswordUpdate = () => {
    toggleShowUpdateSuccess();
    emptyForm();
  };

  const handlePasswordFailure = () => {
    toggleShowUpdateFailure();
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        ["oldPassword"]: { ...prevFormData["oldPassword"], value: "" },
      };
    });
  };

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const key: string = event.target.name;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [key]: {
          ...prevFormData[key],
          value: event.target.value,
        },
      };
    });
  }

  const emptyForm = () => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        ["oldPassword"]: {
          ...prevFormData["oldPassword"],
          value: "",
          valid: true,
        },
        ["password"]: {
          ...prevFormData["password"],
          value: "",
          valid: true,
        },
        ["passwordConfirmation"]: {
          ...prevFormData["passwordConfirmation"],
          value: "",
          valid: true,
        },
      };
    });
  };

  async function validateForm() {
    return (
      passwordValidator(formData.oldPassword.value) &&
      passwordValidator(formData.password.value) &&
      passwordConfirmationValidator(
        formData.passwordConfirmation.value,
        formData.password.value
      )
    );
  }

  async function submitForm() {
    const status = await newPasswordPost(
      formData.oldPassword.value,
      formData.password.value
    );
    if (status === 200) {
      handlePasswordUpdate();
    } else {
      handlePasswordFailure();
    }
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (await validateForm()) {
      changeValidity();
      submitForm();
    } else {
      changeValidity();
    }
  }
  return (
    <div className="max-w-md lg:max-w-lg mx-auto">
      <form onSubmit={handleSubmit}>
        <FormTextInput
          className="mt-6"
          type="password"
          name="oldPassword"
          title="Password"
          placeholder="Enter Your Current Password"
          autoComplete={formData.oldPassword.value}
          onChange={handleChange}
          errorMessage="It doesn't look like that's a valid password."
          value={formData.oldPassword.value}
          valid={formData.oldPassword.valid}
        ></FormTextInput>
        <FormTextInput
          className="mt-6"
          type="password"
          name="password"
          title="New Password"
          placeholder="Enter Your New Password"
          autoComplete={formData.password.value}
          onChange={handleChange}
          errorMessage="Your password must be at least eight characters long,
                        and contain at least one uppercase letter, one lowercase letter
                        and a number."
          value={formData.password.value}
          valid={formData.password.valid}
        ></FormTextInput>
        <FormTextInput
          className="mt-6"
          type="password"
          name="passwordConfirmation"
          title="Confirm Your New Password"
          placeholder="Please confirm your password change"
          autoComplete={formData.passwordConfirmation.value}
          onChange={handleChange}
          errorMessage="Your password confirmation must match your password."
          value={formData.passwordConfirmation.value}
          valid={formData.passwordConfirmation.valid}
        ></FormTextInput>
        <Button
          className="text-center mt-6"
          width={6}
          height={3}
          type="submit"
          buttonType={"primary"}
          buttonText={"Update"}
        />
      </form>
      <PopupModal
        isOpen={showUpdateSuccess}
        onClose={toggleShowUpdateSuccess}
        children={
          <div className="text-center solitreo">
            Your password Has been updated
          </div>
        }
      />
      <PopupModal
        isOpen={showUpdateFailure}
        onClose={toggleShowUpdateFailure}
        children={
          <div className="text-center solitreo">
            The password you entered was incorrect. Please try again.
          </div>
        }
      />
    </div>
  );
};

export default UpdatePasswordForm;
