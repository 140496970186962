import { useState } from "react";
import userProgressionGet from "../api_functions/userProgressionGet";
import Progression from "../models/progression";


export default function StreakView() {
    const [progress, setProgress] = useState<null | Progression>(null);

    if (progress == null) {
        userProgressionGet().then((data) => {
            setProgress(data);
        })
        return (
            <div className="ml-3 retroText">
                <img src="/imgs/progress.gif" alt="loading" className="h-4" />
            </div>
        )
    } else {

        const percent = (progress.next_multiplier != null) ? (progress.streak / progress.next_multiplier.threshold) * 100.0 : 100.0;
        console.log(percent);
        return (
            <div className="ml-3 retroText flex w-auto">
                <div className="w-60 relative progressBar h-5">
                    <div className="progressBarFill h-full text-xs leading-none py-1 text-center text-white" style={{ width: percent + "%" }}></div>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-0 left-0 whitespace-nowrap">
                    {"Streak: " + progress.streak}
                    </div>
                </div>
                    {"MULTIPLIER X" + progress.current_value}
            </div>
        )
    }
}
