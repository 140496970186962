import { Thought } from "../models/thought";
import AxiosInstance from "../utils/AxiosInstance";

export default function MediaPost(journalId: string, new_media: string | null, thought: Thought) {
  const axios = AxiosInstance;
    axios
      .post(`/api/journals/${journalId}/thoughts/${thought.public_id}/upload_media/`, {
        "media": new_media
      }).finally(() => {
        console.log("Posted new media to thought: " + thought.public_id);
      });
}
