import { useState, ChangeEvent, FormEvent } from "react";
import { Form, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import SignUpPost from "../api_functions/SignUpPost";
import FormInput from "../components/FormInput";
import Button from "../components/Button";
import PopupModal from "../components/PopupModal";
import SendVerifyEmail from "../api_functions/sendVerifyEmail"
import { useAuth } from "../utils/useAuth";
import MascotLabeled from "../components/MascotLabeled";

const SignUpPage = () => {
  const auth = useAuth();
  const [formIndex, setFormIndex] = useState(0);
  const [showSignupError, setShowSignupError] = useState(false);
  const nav = useNavigate();
  const toggleSignUpError = () => {
    setShowSignupError(!showSignupError);
  };

  const [formData, setFormData] = useState<{
    [key: string]: any;
  }>({
    firstName: {
      value: "",
      valid: true,
    },
    lastName: {
      value: "",
      valid: true,
    },
    username: {
      value: "",
      valid: true,
    },
    email: {
      value: "",
      valid: true,
    },
    dateOfBirth: {
      value: "",
      valid: true,
    },
    privacyPolicy: {
      value: false,
      valid: true,
    },
    password: {
      value: "",
      valid: true,
    },
    passwordConfirmation: {
      value: "",
      valid: true,
    },
  });

  const keysByIndex: { [key: number]: string } = {
    0: "firstName",
    1: "lastName",
    2: "username",
    3: "email",
    4: "dateOfBirth",
    5: "privacyPolicy",
    6: "password",
    7: "passwordConfirmation",
  };

  function changeFormTextData(event: ChangeEvent<HTMLInputElement>) {
    setFormData((prevFormData) => {
      const key: string = event.target.name;
      return {
        ...prevFormData,
        [key]: {
          ...prevFormData[key],
          value: event.target.value,
        },
      };
    });
  }

  function changeFormCheckboxData(event: ChangeEvent<HTMLInputElement>) {
    setFormData((prevFormData) => {
      const key: string = event.target.name;
      return {
        ...prevFormData,
        [key]: {
          ...prevFormData[key],
          value: !prevFormData[key].value,
        },
      };
    });
  }

  function changeValidity() {
    setFormData((prevFormData) => {
      const key: string = keysByIndex[formIndex];
      const valid: boolean = validateField();
      return {
        ...prevFormData,
        [key]: {
          ...prevFormData[key],
          valid: valid,
        },
      };
    });
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.type === "checkbox") {
      changeFormCheckboxData(event);
    } else {
      changeFormTextData(event);
    }
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (formIndex < 8) {
      goForward();
    } else {
      const username = formData.username.value;
      const email = formData.email.value;
      const password = formData.password.value;
      const dateOfBirth = formData.dateOfBirth.value;
      const firstName = formData.firstName.value;
      const lastName = formData.lastName.value;

      const status = await SignUpPost(
        username,
        email,
        password,
        firstName,
        lastName,
        dateOfBirth
      );

      if (status === 201) {
        const status = await auth?.signIn(formData.username.value, formData.password.value);
        SendVerifyEmail()
        nav("/verifyemail");
      } else {
        handleSignUpError();
      }
    }
  }
  function incrementFormIndex() {
    setFormIndex((prevformIndex) => prevformIndex + 1);
  }
  function decrementFormIndex() {
    setFormIndex((prevformIndex) => prevformIndex - 1);
  }
  function firstNameValidator() {
    const regex = new RegExp(/^([0-9]*[a-zA-Z]){3,}[0-9]*$/);
    return regex.test(formData.firstName.value);
  }
  function lastNameValidator() {
    const regex = new RegExp(/^([0-9]*[a-zA-Z]){3,}[0-9]*$/);
    return regex.test(formData.lastName.value);
  }
  function usernameValidator() {
    const regex = new RegExp(/[0-9a-zA-Z]{6,}/);
    return regex.test(formData.username.value);
  }
  function emailValidator() {
    const pattern = /^\S+@\S+\.\S+$/;
    const regex = RegExp(pattern);
    return regex.test(formData.email.value);
  }

  function dateOfBirthValidator() {
    const today = new Date();
    return dayjs(formData.dateOfBirth.value).add(15, "year").isBefore(today);
  }
  function privacyPolicyValidator() {
    return formData.privacyPolicy.value === true;
  }
  function passwordValidator() {
    const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    return regex.test(formData.password.value);
  }
  function passwordConfirmationValidator() {
    return formData.passwordConfirmation.value === formData.password.value;
  }

  function getValidator(index: number) {
    switch (index) {
      case 0:
        return firstNameValidator;
      case 1:
        return lastNameValidator;
      case 2:
        return usernameValidator;
      case 3:
        return emailValidator;
      case 4:
        return dateOfBirthValidator;
      case 5:
        return privacyPolicyValidator;
      case 6:
        return passwordValidator;
      case 7:
        return passwordConfirmationValidator;
    }
  }

  function validateField() {
    const validator = getValidator(formIndex);
    if (validator) {
      const isValid = validator();
      return isValid;
    } else return true;
  }

  function goForward() {
    if (validateField()) {
      changeValidity();
      incrementFormIndex();
    } else {
      changeValidity();
    }
  }
  function goBackward() {
    decrementFormIndex();
  }

  function handleSignUpError() {
    setFormIndex(2);
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        ["username"]: {
          value: "",
          valid: true,
        },
        ["email"]: {
          value: "",
          valid: true,
        },
      };
    });

    toggleSignUpError();
  }

  return (
    <div
      className="max-w-md mt-24
     mx-auto  px-8 pt-8 pb-12 bg-yellow-100 border-8 border-pink-100 "
    >
      
      {formIndex == 0 && <MascotLabeled width={100} height={100} mt={"mt-6"} label="Can you provide me your first name?"/>}
      {formIndex == 1 && <MascotLabeled width={100} height={100} mt={"mt-6"} label="Can you provide me your last name?"/>}
      {formIndex == 2 && <MascotLabeled width={100} height={100} mt={"mt-6"} label="Can you provide me your username?"/>}
      {formIndex == 3 && <MascotLabeled width={100} height={100} mt={"mt-6"} label="Can you provide me your email?"/>}
      {formIndex == 4 && <MascotLabeled width={100} height={100} mt={"mt-6"} label="Can you provide me your date of birth?"/>}
      {formIndex == 5 && <MascotLabeled width={100} height={100} mt={"mt-6"} label="Can you check our privacy policy?"/>}
      {formIndex == 6 && <MascotLabeled width={100} height={100} mt={"mt-6"} label="Can you enter your password?"/>}
      {formIndex == 7 && <MascotLabeled width={100} height={100} mt={"mt-6"} label="Can you confirm your password?"/>}
      {formIndex == 8 && <MascotLabeled width={100} height={100} mt={"mt-6"} label="Let's go!!!"/>}

      <h1 className="text-3xl text-center retroText text-pink-">Sign Up</h1>
      <Form onSubmit={handleSubmit} className="mt-8">
        <FormInput
          type="text"
          name="firstName"
          title="First Name"
          className={formIndex !== 0 ? "invisible absolute" : "block"}
          placeholder="Enter Your first name"
          errorMessage="Your first name must be at least three characters long."
          value={formData.firstName.value}
          onChange={handleChange}
          valid={formData.firstName.valid}
          autoComplete={formData.firstName.value}
        />
        <FormInput
          type="text"
          name="lastName"
          title="Last Name"
          className={formIndex !== 1 ? "invisible absolute" : "block"}
          placeholder="Enter Your last name"
          errorMessage="Your last name must be at least three characters long."
          checked={formData.lastName.value}
          onChange={handleChange}
          valid={formData.lastName.valid}
          autoComplete={formData.lastName.value}
        />
        <FormInput
          type="text"
          name="username"
          title="Username"
          className={formIndex !== 2 ? "invisible absolute" : "block"}
          placeholder="Enter Your Username"
          errorMessage="Your username must be at least six characters long."
          value={formData.username.value}
          onChange={handleChange}
          valid={formData.username.valid}
          autoComplete={formData.username.value}
        />

        <FormInput
          type="text"
          className={formIndex !== 3 ? "invisible absolute" : "block"}
          name="email"
          placeholder="Enter Your email"
          title="Email"
          autoComplete={formData.email.value}
          onChange={handleChange}
          errorMessage="You must input a valid email address!"
          value={formData.email.value}
          valid={formData.email.valid}
        />
        <FormInput
          type="date"
          className={formIndex !== 4 ? "invisible absolute" : "block"}
          name="dateOfBirth"
          placeholder="Enter your date of birth"
          onChange={handleChange}
          title="Date of Birth"
          autoComplete={formData.dateOfBirth.value}
          errorMessage="You must be at least 15 to use this site"
          value={formData.dateOfBirth.value}
          valid={formData.dateOfBirth.valid}
        />
        <FormInput
          type="checkbox"
          className={formIndex !== 5 ? "invisible absolute" : "block"}
          name="privacyPolicy"
          title="Privacy Policy"
          description="You must agree to our privacy policy to use our site."
          onChange={handleChange}
          checked={formData.privacyPolicy.value}
          valid={formData.privacyPolicy.valid}
          errorMessage="You must agree to our privacy policy to use our site"
        />
        <FormInput
          type="password"
          className={formIndex !== 6 ? "invisible absolute" : "block"}
          name="password"
          title="Password"
          placeholder="Enter Your Password"
          autoComplete={formData.password.value}
          onChange={handleChange}
          errorMessage="Your password must be at least eight characters long,
                        and contain at least one uppercase letter, one lowercase letter
                        and a number."
          value={formData.password.value}
          valid={formData.password.valid}
        ></FormInput>
        <FormInput
          type="password"
          className={formIndex !== 7 ? "invisible absolute" : "block"}
          name="passwordConfirmation"
          placeholder="Confirm Your Password"
          title="Password Confirmation"
          onChange={handleChange}
          autoComplete={formData.passwordConfirmation.value}
          errorMessage="Your password confirmation must match your original password."
          value={formData.passwordConfirmation.value}
          valid={formData.passwordConfirmation.valid}
        ></FormInput>
        <Button
          width={10}
          height={2.5}
          type="submit"
          buttonType={"primary"}
          buttonText={"Sign me up!"}
          className={
            formIndex !== 8 ? "invisible absolute" : "block text-center"
          }
        />
      </Form>
      <div className="flex justify-evenly">
        {formIndex > 0 && (
          <Button
            className="mt-5"
            width={6}
            height={3}
            buttonType={"primary"}
            buttonText={"back"}
            onClick={goBackward}
          />
        )}
        {formIndex < 8 && (
          <Button
            className="mt-5"
            width={6}
            height={3}
            buttonType={"primary"}
            buttonText={"next"}
            onClick={goForward}
          />
        )}
      </div>
      <PopupModal
        isOpen={showSignupError}
        onClose={toggleSignUpError}
        children={
          <p className="text-center">
            That username or email is already in use.
          </p>
        }
      />
    </div>
  );
};
export default SignUpPage;
