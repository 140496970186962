import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useRef } from "react";
import Button from "./Button";
import HomeMascot from "./HomeMascot";
interface PopupModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  showMascot?: boolean;
  mascotWidth?: number;
  mascotHeight?: number;
}
const PopupModal = ({ isOpen, onClose, children, showMascot, mascotWidth, mascotHeight }: PopupModalProps) => {
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpenState, setIsOpenState] = useState<boolean>(isOpen);
  const handleClose = () => {
    setIsOpenState(false);
    onClose();
  };
  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.clientHeight);
    }
  }, [children]);
  return (
    <Modal
      ariaHideApp={false}
      className={` mx-auto my-10 max-w-lg border-8 border-pink-100  bg-yellow-100 h-${contentHeight} p-8`}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Popup Modal"
    >
      <div ref={contentRef} className={"flex"} id="modal-root">
        {showMascot && <HomeMascot width={mascotWidth} height={mascotHeight} />}
        {children}
        <Button
          width={0}
          onClick={handleClose}
          height={0}
          className="text-center mt-4"
          buttonType={"secondary"}
          buttonText={"Close"}
        ></Button>
      </div>
    </Modal>
  );
};

export default PopupModal;
