import { useEffect, useState } from "react";
import { Journal } from "../models/Journal";
import journalsGet from "../api_functions/journalsGet";
import { downloadPDF } from "../utils/downloadPDF";
import Button from "../components/Button";

const DownloadJournalsPage = () => {
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState<string[]>([]);
  const [selectedJournal, setSelectedJournal] = useState<Journal>();
  const [journals, setJournals] = useState<Journal[]>([]);
  const [journalSelected, setJournalSelected] = useState(false);

  useEffect(() => {
    const loadJournals = async () => {
      setJournals(await journalsGet());
    };
    loadJournals();
  }, []);

  useEffect(() => {
    if (journals) {
      let dates: string[] = [];
      for (let journal of journals!) {
        dates.push(journal.date.toString());
      }
      setDates(dates);
      setLoading(false);
    }
  }, [journals]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    downloadPDF(selectedJournal!.public_id);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedDate = dates[selectedIndex - 1];
    for (const journal of journals!) {
      if (journal.date.toString() === selectedDate.toString()) {
        setSelectedJournal(journal);
      }
    }
    setJournalSelected(true);
  };

  return loading ? (
    <div></div>
  ) : (
    <div className="max-w-md mx-auto">
      {journals.length > 0 && (
        <div className="">
          <h1 className="retroText text-center text-slate-700">
            Please select the date if the journal you would like to download.
          </h1>
          <select
            className="mt-6 rounded-md w-full mx-auto"
            onChange={handleSelectChange}
          >
            <option>Click to see dates</option>
            {dates.map((date, index) => (
              <option key={index} value={index}>
                {new Date(date).toString().substring(0, 15)}
              </option>
            ))}
          </select>
        </div>
      )}
      <form onSubmit={handleSubmit} className={journalSelected ? "" : "hidden"}>
        <Button
          className="text-center mt-6"
          width={0}
          height={0}
          type="submit"
          buttonType={"primary"}
          buttonText={"Download"}
        ></Button>
      </form>
    </div>
  );
};

export default DownloadJournalsPage;
