import axios from "axios";
import { Mood } from "../models/mood";

export default function MoodPost(mood: Mood, journalId: string) {
  axios({
    method: "patch",
    url: `api/journals/${journalId}`,
    data: {
      mood: mood.mood_type,
    },
  });
}
