import AxiosInstance from "../utils/AxiosInstance";

const newPasswordPost = (oldPassword: string, newPassword: string) => {
  const axios = AxiosInstance;

  return axios
    .patch("api/users/change_password/", {
      old_password: oldPassword,
      new_password: newPassword,
    })
    .then((response) => {
      return response.status;
    })
    .catch((error) => {
      return error.status;
    });
};

export default newPasswordPost;
