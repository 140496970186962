import { IFormCheckboxInput } from "./FormCheckboxInput";

export type IFormCheckboxInputNormal = IFormCheckboxInput;

function FormCheckboxInputNormal(props: IFormCheckboxInputNormal) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          type="checkbox"
          value={props.value}
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
          className="h-4 w-4  border-gray-300 text-blue-400 "
        />
      </div>
      <div className="ml-3 text-sm">
        <label className="font-medium retroText ">{props.title}</label>
        <p className=" solitreo">{props.description}</p>
      </div>
    </div>
  );
}

export default FormCheckboxInputNormal;
