import React from 'react'
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom'
import useAxios from '../utils/useAxios'
import { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';

export type VerificationArgs = {
    username: string,
    token: string,
}

export async function loadCode({params}: LoaderFunctionArgs) {
    const args: VerificationArgs = {username: params.username!, token: params.token!}
    return (
        args
    )
}

const VerifyWithCode = () => {
    const [loading, setLoading] = useState(true)
    const [valid, setValid] = useState(false)
    const userData = useLoaderData() as VerificationArgs
    useEffect(() => {
        axios.post(
            "/api/verifyemail",
        {username: userData.username,
            verification_token: userData.token}
        ).then(
            function (response) {
                if (response.status === 200){
                    setLoading(false)
                    setValid(true)
                }
                else{
                    setLoading(false)
                }
            }
        ).catch(
            function (error) {
                setLoading(false)
                setValid(false)
            }
        )})
    
    return (
    <div>
        {loading? <h1>loading</h1> : valid? 
        <div className="max-w-lg mt-8 border-8 border-pink-100 p-4 bg-yellow-100 mx-auto text-center">
            <h1 className="text-xl underline retroText mt-6">Email verification successful</h1>
            <p className="solitreo mt-4">
            Your email is now verified. Click the home icon in the top left to be redirected to the home page.
            </p>
        </div>
        : 
        <div className="max-w-lg mt-8 border-8 border-pink-100 p-4 bg-yellow-100 mx-auto text-center">
            <h1 className="text-xl underline retroText mt-6">Email verification failed</h1>
            <p className="solitreo mt-4">
            Sorry, we couldn't verify your email at this time. Please try again.
            </p>
        </div>
        }
    </div>
  )
}

export default VerifyWithCode