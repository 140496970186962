import { Journal } from "../models/Journal";
import AxiosInstance from "../utils/AxiosInstance";

const getJournal = async (publicID: string) => {
  const axios = AxiosInstance;
  const journal: Journal = await axios
    .get(`api/journals/${publicID}/`)
    .then(function (response) {
      return response.data;
    });
  return journal;
};

export default getJournal;
