import { IButton } from "./Button";

export type ISecondaryButton = IButton;

function SecondaryButton(props: ISecondaryButton) {
  return (
    <button
      type={props.type}
      onClick={props.onClick}
      className={`px-4 py-2 inline-flex items-center  border border-transparent bg-indigo-100  text-base retroText  hover:bg-indigo-200 `}
    >
      {props.buttonText}
    </button>
  );
}

export default SecondaryButton;
