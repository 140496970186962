export default function HomeFooter() {
  return (
    <footer className="flex justify-center fixed inset-x-0 bottom-0 bg-calm-pink space-x-3 border-2 border-calm-peach">
      <a href="/privacypolicy">
        <p>Privacy Policy</p>
      </a>
      <a href="/teaminformation">
        <p>The Team</p>
      </a>
    </footer>
  );
}
