import "./../index.css";

interface Props {
  title: string;
  gif: string;
  description: string;
  width?: number;
}

function BulletPoint({ title, gif, description, width }: Props) {
  return (
    <div className="mt-3 flex">
      <div>
        <img className="rounded-br-md" width={width ? width : 42} src={gif} />
      </div>
      <div className="ml-3">
        <p className="retroText">{title}</p>
        <p className="solitreo">{description}</p>
      </div>
    </div>
  );
}

export default BulletPoint;
