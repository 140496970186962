import AxiosInstance from "../utils/AxiosInstance";

const deleteAccountPost = (username: string, password: string) => {
  const axios = AxiosInstance;

  return axios.post("api/users/delete/", {
    username: username,
    password: password,
  });
};

export default deleteAccountPost;
