import { Prompt } from "../models/prompt";

interface props {
  prompt: Prompt;
  onClickHandler: (id: Prompt) => void;
}

function PromptView({ prompt, onClickHandler }: props) {
  const promptStyle = {
    backgroundColor: prompt.colour.toString(),
  };

  return (
    <div
      className="w-11 m-1 rounded inline-block border border-solid border-black"
      style={promptStyle}
    >
      <img
        width={48}
        height={48}
        src={`imgs/${prompt.icon}.svg`}
        onClick={() => onClickHandler(prompt)}
      ></img>
    </div>
  );
}

export default PromptView;
