import { IButton } from "./Button";

export type IPrimaryButton = IButton;

function PrimaryButton(props: IPrimaryButton) {
  const width = "px-" + props.width.toString();
  const height = "py-" + props.height.toString();

  return (
    <button
      type={props.type}
      onClick={props.onClick}
      className={`px-4 py-2 inline-flex items-center retroText border border-transparent bg-green-100 text-base font-medium  shadow-sm hover:bg-green-300 `}
    >
      {props.buttonText}
    </button>
  );
}

export default PrimaryButton;
