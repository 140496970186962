import AxiosInstance from "../utils/AxiosInstance";
import { Journal } from "../models/Journal";

const journalsGet = async () => {
  const axios = AxiosInstance;
  const journals = axios.get("api/journals/").then(function (response) {
    return response.data;
  });

  return journals;
};

export default journalsGet;
