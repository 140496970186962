import HomeMascot from "./HomeMascot"

interface Props {
    label: string;
    width?: number;
    height?: number
    mt?: string;
}

function MascotLabeled({label, width, height, mt}: Props) {
  const mtClass = mt? mt : "mt-12";

  return (
    <div className="flex justify-center content-center">
        <HomeMascot width={width} height={height} />
        <p className= {mtClass + " solitreo text-xl"}>{label}</p>
    </div>
  )
}

export default MascotLabeled;