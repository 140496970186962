import { ComponentProps } from "react";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

export interface IButton extends ComponentProps<"button"> {
  width: number;
  height: number;
  buttonType: "primary" | "secondary";
  buttonText: string;
}

function Button(props: IButton) {
  const renderSwitch = (props: IButton) => {
    switch (props.buttonType) {
      case "primary":
        return <PrimaryButton {...props} />;
      case "secondary":
        return <SecondaryButton {...props} />;
    }
  };
  return <div className={props.className}>{renderSwitch(props)}</div>;
}

export default Button;
