import AxiosInstance from "../utils/AxiosInstance";

const settingsPost = (
  firstName: string,
  lastName: string,
  username: string,
  email: string,
  dateOfBirth: string
) => {
  const axios = AxiosInstance;

  return axios
    .put(`api/users/updateUser/`, {
      first_name: firstName,
      last_name: lastName,
      username: username,
      email: email,
      date_of_birth: dateOfBirth,
    })
    .then(function (response) {
      return response.status;
    })
    .catch(function (error) {
      return error.status;
    });
};

export default settingsPost;
