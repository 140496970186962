import { useState } from "react";
import { IFormTextInput } from "./FormTextInput";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export interface IFormTextInputNormal extends IFormTextInput {}

export default function FormTextInputNormal(props: IFormTextInputNormal) {
  const [type, setType] = useState(props.type);
  const originalType = props.type;
  return (
    <div className={props.className}>
      <div className="flex items-center w-full justify-between">
        <label
          htmlFor={props.title}
          className="block retroText text-sm font-medium text-gray-700"
        >
          {props.title}
        </label>
        <div>
          {originalType === "password" &&
            (type === "password" ? (
              <AiOutlineEye onClick={() => setType("text")} />
            ) : (
              <AiOutlineEyeInvisible onClick={() => setType("password")} />
            ))}
        </div>
      </div>
      <div>
        <input
          type={type}
          name={props.name}
          id={props.id}
          value={props.value}
          autoComplete={props.autoComplete}
          onChange={props.onChange}
          className={
            " solitreo block w-full rounded-md border-gray-300 shadow-sm  sm:text-sm"
          }
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
}
