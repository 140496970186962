import { useState } from "react";
import Button from "../components/Button";
import UpdatePasswordForm from "../components/UpdatePasswordForm";
import PopupModal from "../components/PopupModal";
import MainSettingsForm from "../components/MainSettingsForm";
import DeleteAccountForm from "../components/DeleteAccountForm";

const SettingsPage = () => {
  const [passwordFormOpen, setPasswordFormOpen] = useState(false);
  const [mainFormOpen, setMainFormOpen] = useState(false);
  const [deleteFormOpen, setDeleteFormOpen] = useState(false);
  const togglePasswordForm = () => {
    setPasswordFormOpen(!passwordFormOpen);
  };
  const toggleMainForm = () => {
    setMainFormOpen(!mainFormOpen);
  };
  const toggleDeleteForm = () => {
    setDeleteFormOpen(!deleteFormOpen);
  };

  return (
    <div className="max-w-lg mx-auto bg-yellow-100 border-8 border-pink-200 p-4 pb-8 mt-16">
      <h1 className="text-3xl text-center retroText mt-6">Settings</h1>
      <PopupModal
        isOpen={mainFormOpen}
        onClose={toggleMainForm}
        children={<MainSettingsForm />}
      />
      <PopupModal
        isOpen={passwordFormOpen}
        onClose={togglePasswordForm}
        children={<UpdatePasswordForm />}
      />
      <PopupModal
        isOpen={deleteFormOpen}
        onClose={toggleDeleteForm}
        children={<DeleteAccountForm />}
      />
      <Button
        className="text-center mt-8"
        onClick={toggleMainForm}
        width={0}
        height={0}
        buttonType={"primary"}
        buttonText={"Change User Details"}
      />
      <Button
        className="text-center mt-6"
        onClick={togglePasswordForm}
        width={0}
        height={0}
        buttonType={"primary"}
        buttonText={"Change Password"}
      />
      <Button
        className="text-center mt-6"
        onClick={toggleDeleteForm}
        width={0}
        height={0}
        buttonType={"primary"}
        buttonText={"Delete Account"}
      />
    </div>
  );
};

export default SettingsPage;
