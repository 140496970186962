import HomeLinks from "../components/HomeLinks";

import HomeMascot from "../components/HomeMascot";

export default function HomePage() {
  return (
    <div
      className="max-w-xl mx-auto border-8 mt-8 border-purple-200 bg-calm-blue pb-12
    "
    >
      <div className="flex justify-center">
        <HomeMascot />
      </div>
      <div className="flex justify-center">
        <p className="mx-6 solitreo text-xl">Welcome to Habithatch, stat journling whenever you wish!!!</p>
      </div>
      <div className="mt-8">
        <HomeLinks />
      </div>
    </div>
  );
}
