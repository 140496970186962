const TeamInfo = () => {
  return (
    <div className="max-w-lg mt-8 border-8 border-pink-100 p-4 bg-yellow-100 mx-auto text-center">
      <h1 className="text-xl underline retroText mt-6">Our Team</h1>
      <p className="solitreo mt-4">
        This website was developed by the knuthleheads. We are a group of
        computer science students at King's College, London. For more information please
        contact{" "}
        <a
          className="text-blue-800 underline "
          href="mailto: support@habithatch.knuthleheads.uk"
        >
          support@habithatch.knuthleheads.uk
        </a>
        .
      </p>
    </div>
  );
};

export default TeamInfo;
