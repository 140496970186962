import { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router";
import deleteAccountPost from "../api_functions/deleteAccountPost";
import { passwordValidator, usernameValidator } from "../utils/FormValidators";
import { useAuth } from "../utils/useAuth";
import Button from "./Button";
import FormTextInput from "./FormTextInput";

const DeleteAccountForm = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [formData, setFormData] = useState<{
    [key: string]: any;
  }>({
    username: {
      value: "",
      valid: true,
      validator: usernameValidator,
    },
    password: {
      value: "",
      valid: true,
      validator: passwordValidator,
    },
  });

  async function changeValidity() {
    const updatedFormData: { [key: string]: any } = {};
    for (const key in formData) {
      const field = formData[key];
      updatedFormData[key] = {
        ...field,
        valid: await field.validator(field.value),
      };
    }
    setFormData(updatedFormData);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const key: string = event.target.name;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [key]: {
          ...prevFormData[key],
          value: event.target.value,
        },
      };
    });
  }

  async function validateForm() {
    return (
      (await usernameValidator(formData.username.value)) &&
      passwordValidator(formData.password.value)
    );
  }

  async function submitForm() {
    const status = await deleteAccountPost(
      formData.username.value,
      formData.password.value
    );
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (await validateForm()) {
      changeValidity();
      submitForm();
      auth?.signOut();
      navigate("/");
    } else {
      changeValidity();
    }
  }
  return (
    <div className="max-w-md lg:max-w-lg mx-auto">
      <p className="retroText text-gray-700 text-center">
        To confirm that you would like to delete your account, please enter your
        username and password
      </p>
      <form onSubmit={handleSubmit}>
        <FormTextInput
          className="mt-6"
          name="username"
          title="Username"
          type="text"
          placeholder="Enter Your Current Username"
          autoComplete={formData.username.value}
          onChange={handleChange}
          errorMessage="It doesn't look like that's your username."
          value={formData.username.value}
          valid={formData.username.valid}
        ></FormTextInput>
        <FormTextInput
          className="mt-6"
          type="password"
          name="password"
          title="Password"
          placeholder="Enter Your Current Password"
          autoComplete={formData.password.value}
          onChange={handleChange}
          errorMessage="It doesn't look like that's your password."
          value={formData.password.value}
          valid={formData.password.valid}
        ></FormTextInput>
        <Button
          className="text-center mt-6"
          width={6}
          height={3}
          type="submit"
          buttonType={"primary"}
          buttonText={"Delete"}
        />
      </form>
    </div>
  );
};

export default DeleteAccountForm;
