import { useNavigate } from "react-router";
import Button from "../components/Button";

export default function EmailVerificationPage() {
  const nav = useNavigate();
  return (
    <div className="max-w-lg mx-auto mt-6 text-center p-16 bg-yellow-100 border-8 border-pink-100 ">
      <h1 className="text-3xl retroText ">Verify Your Email</h1>
      <h1 className="text-gray-500 mt-4">
        A message has been sent to the address you entered
      </h1>
      <h1 className="text-gray-500 ">
        You need to verify your account to access most features of this website
      </h1>
      <Button
        width={0}
        height={0}
        onClick={() => nav("/home")}
        className="mt-4"
        buttonType={"primary"}
        buttonText={"I understand"}
      />
    </div>
  );
}
