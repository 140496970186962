
interface Props {
  width?: number;
  height?: number
}

export default function HomeMascot({width, height}: Props) {
  return (
    <div className="">
      <img
        src="/imgs/Big_Oblet_slime.png"
        width={width || "350"}
        height={height || "350"}
        className=""
      />
    </div>
  );
}
