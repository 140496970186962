import BulletPoint from "../components/BulletPoint";
import MascotLabeled from "../components/MascotLabeled";
import "./../index.css";

export default function RootPage() {
  return (
    <div className="max-w-lg mx-auto">
      <div className="flex justify-center bg-purple-100 p-3 rounded-br-md mt-6">
        <p className="retroText">Your place to journal!</p>
      </div>

      <div className="mt-6">
        <MascotLabeled label="Hi, Oblet, I'm the mascot on this website, you will see me a lot here, this is my home. Check our journaling website, Habithatch!" />
      </div>

      <p className="flex justify-center retroText mt-6">What we offer!</p>
      <div className="bg-purple-100 p-3 rounded-br-md mt-2">
        <BulletPoint
          title="Select your mood for the day"
          gif="imgs/sun-animation.gif"
          description="You can select how do you feel this day!"
        />
        <BulletPoint
          title="Choose from various prompts"
          gif="imgs/chose.gif"
          description="You can use our suggested prompts!"
        />
        <BulletPoint
          title="Write freely"
          gif="imgs/pen.gif"
          description="You can write anything you want with a blank prompt!"
        />
        <BulletPoint
          title="Consistency!"
          gif="imgs/bullseye.gif"
          description="We will remind you to write journals on the days you missed!"
        />
        <BulletPoint
          title="Progress tracking!"
          gif="imgs/progress.gif"
          description="We will show you how active you are in building this habit!"
        />
      </div>

      <div className="flex retroText mt-3 justify-center"></div>
    </div>
  );
}
