import { Mood } from "../models/mood";

interface props {
  currentMood: Mood;
  setCurrentMood: (mood: Mood) => void;
}

export default function MoodEmoji({ currentMood, setCurrentMood }: props) {
  function changeCurrentMood() {
    setCurrentMood(currentMood);
  }

  return (
    <div className="hover:bg-pink-300 active:bg-green-300">
      <img
        src={`imgs/${currentMood.img_string}`}
        height={100}
        width={100}
        className="border border-solid border-black"
        onClick={changeCurrentMood}
      />
    </div>
  );
}
