import { ComponentProps } from "react";
import FormCheckboxInput from "./FormCheckboxInput";
import FormDropdownInput from "./FormSelect";
import FormTextInput from "./FormTextInput";

export interface IFormInput extends ComponentProps<"input"> {
  valid: boolean;
  errorMessage: string;
  title?: string;
  description?: string;
  options?: string[];
}

function FormInput(props: IFormInput) {
  const renderSwitch = (props: IFormInput) => {
    switch (props.type) {
      case "text":
        return <FormTextInput {...props} />;
      case "password":
        return <FormTextInput {...props} />;
      case "email":
        return <FormTextInput {...props} />;
      case "date":
        return <FormTextInput {...props} />;
      case "checkbox":
        return <FormCheckboxInput {...props} />;
    }
  };
  return <div>{renderSwitch(props)}</div>;
}

export default FormInput;
