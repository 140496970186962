import AxiosInstance from "../utils/AxiosInstance";
import getUserID from "../utils/getUserID";

const settingsGet = () => {
  const axios = AxiosInstance;
  const user_id: number = getUserID();
  return axios
    .get(`api/users/get_user/`, {})
    .then(function ({ data }) {
      return data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export default settingsGet;
