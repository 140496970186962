import { Thought } from "../models/thought";
import AxiosInstance from "../utils/AxiosInstance";

export default async function ThoughtPost(journalId: string, thought: Thought, saveThought: (thought: Thought) => void) {
  const axios = AxiosInstance;
    axios
      .post(`api/journals/${journalId}/thoughts/`, {
        prompt: thought.prompt,
        text: thought.text
      })
      .then(function ({ data }) {
        saveThought({...thought, public_id: data.public_id});
      });
  
}
