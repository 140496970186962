const NotFound = () => {
  return (
    <div className="text-center border-8 p-6 mt-6 border-pink-200 max-w-3xl mx-auto bg-yellow-100">
      <p className="text-base retroText font-semibold text-slate-700">404</p>
      <h1 className="mt-4 text-3xl retroText font-bold tracking-tight text-gray-900 sm:text-5xl">
        Page not found
      </h1>
      <p className="mt-6 text-base leading-7 solitreo text-gray-600">
        Sorry, we couldn’t find the page you’re looking for.
      </p>
    </div>
  );
};

export default NotFound;
