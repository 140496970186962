import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const exportPDF = (title: string, paragraphs: string[]) => {
  const docDefinition = {
    content: [
      {
        text: title,
        bold: true,
        fontSize: 16,
      },
      ...paragraphs.map((text) => ({ text })),
    ],
    info: {
      title,
    },
  };

  const pdfDocGenerator = pdfMake.createPdf(docDefinition);

  pdfDocGenerator.getBlob((blob) => {
    const filename = `${title}.pdf`;
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
