import dayjs from "dayjs";
import passwordCheck from "../api_functions/passwordCheck";

export function firstNameValidator(firstName: string) {
  const regex = new RegExp(/^([0-9]*[a-zA-Z]){3,50}[0-9]*$/);
  return regex.test(firstName);
}
export function lastNameValidator(lastName: string) {
  const regex = new RegExp(/^([0-9]*[a-zA-Z]){3,50}[0-9]*$/);
  return regex.test(lastName);
}
export function usernameValidator(username: string) {
  const regex = new RegExp(/[0-9a-zA-Z]{6,}/);
  return regex.test(username);
}
export function emailValidator(email: string) {
  const pattern = /^\S+@\S+\.\S+$/;
  const regex = RegExp(pattern);
  return regex.test(email);
}

export function dateOfBirthValidator(dateOfBirth: string) {
  const today = new Date();
  return dayjs(dateOfBirth).add(15, "year").isBefore(today);
}
export function passwordValidator(password: string) {
  const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
  return regex.test(password);
}
export function passwordConfirmationValidator(
  passwordConfirmation: string,
  password: string
) {
  return passwordConfirmation === password;
}

export async function currentPasswordValidator(currentPassword: string) {
  const status = await passwordCheck(currentPassword);
  return status === 200;
}
