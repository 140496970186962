import axios from "axios";

export default function SignUpPost(
  username: string,
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  dateOfBirth: string
) {
  return axios({
    method: "post",
    url: "/api/users/createUser/",
    data: {
      username: username,
      first_name: firstName,
      last_name: lastName,
      email: email,
      date_of_birth: dateOfBirth,
      password: password,
    },
  })
    .then(function (response) {
      return response.status;
    })
    .catch(function (error) {
      return error;
    });
}
