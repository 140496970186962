import jwtDecode from "jwt-decode";
import { AuthTokens } from "../models/AuthTokens";
import JWTDecoded from "../models/JWTDecoded";

const getUserID = () => {
  const authTokens: AuthTokens = JSON.parse(
    localStorage.getItem("authTokens")!
  );
  const { user_id } = jwtDecode(authTokens.access) as JWTDecoded;
  return user_id;
};

export default getUserID;
