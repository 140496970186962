import { Thought } from "../models/thought";
import AxiosInstance from "../utils/AxiosInstance";

export default async function ThoughtPut(journalId: string, thought: Thought) {
  const axios = AxiosInstance;
    axios
      .put(`api/journals/${journalId}/thoughts/${thought.public_id}/`, {
        prompt: thought.prompt,
        text: thought.text
      });
}
