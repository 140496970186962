import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import settingsGet from "../api_functions/settingsGet";
import settingsPost from "../api_functions/settingsPost";
import fakeGetDetails from "../utils/fakeGetDetails";
import {
  dateOfBirthValidator,
  emailValidator,
  firstNameValidator,
  lastNameValidator,
  usernameValidator,
} from "../utils/FormValidators";
import Button from "./Button";
import FormTextInput from "./FormTextInput";

const MainSettingsForm = () => {
  const [formSuccess, setFormSuccess] = useState(false);
  const [formFailure, setFormFailure] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const details = await settingsGet();
      const data: {
        [key: string]: any;
      } = {
        username: details.username,
        firstName: details.first_name,
        lastName: details.last_name,
        email: details.email,
        dateOfBirth: details.date_of_birth,
      };

      for (const key in data) {
        const detail = data[key];
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            [key]: {
              ...prevFormData[key],
              value: detail,
            },
          };
        });
      }
    };
    fetchData();
  }, []);
  const [formData, setFormData] = useState<{
    [key: string]: any;
  }>({
    firstName: {
      value: "",
      valid: true,
      validator: firstNameValidator,
    },
    lastName: {
      value: "",
      valid: true,
      validator: lastNameValidator,
    },
    username: {
      value: "",
      valid: true,
      validator: usernameValidator,
    },
    email: {
      value: "",
      valid: true,
      validator: emailValidator,
    },
    dateOfBirth: {
      value: "",
      valid: true,
      validator: dateOfBirthValidator,
    },
  });

  function changeValidity() {
    const updatedFormData: { [key: string]: any } = {};
    for (const key in formData) {
      const field = formData[key];
      updatedFormData[key] = {
        ...field,
        valid: field.validator(field.value),
      };
    }
    setFormData(updatedFormData);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const key: string = event.target.name;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [key]: {
          ...prevFormData[key],
          value: event.target.value,
        },
      };
    });
  }

  function validateForm(): boolean {
    return (
      firstNameValidator(formData.firstName.value) &&
      lastNameValidator(formData.lastName.value) &&
      usernameValidator(formData.username.value) &&
      emailValidator(formData.email.value) &&
      dateOfBirthValidator(formData.dateOfBirth.value)
    );
  }

  async function submitForm() {
    const status = await settingsPost(
      formData.firstName.value,
      formData.lastName.value,
      formData.username.value,
      formData.email.value,
      formData.dateOfBirth.value
    );

    if (status === 200) {
      handleSuccess();
    } else {
      handleFailure();
    }
  }

  const handleSuccess = () => {
    setFormSuccess(true);
    setTimeout(() => {
      setFormSuccess(false);
    }, 3000);
  };
  const handleFailure = () => {
    setFormFailure(true);
    setTimeout(() => {
      setFormFailure(false);
    }, 3000);
  };

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (validateForm()) {
      changeValidity();
      submitForm();
    } else {
      changeValidity();
    }
  }

  return (
    <div className="max-w-md lg:max-w-lg mx-auto">
      {formSuccess && <div>Your details have been updated</div>}
      {formFailure && (
        <div>
          The email or username you entered are already being used by another
          user
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <FormTextInput
          className="mt-16"
          type="text"
          name="firstName"
          title="First Name"
          placeholder="Enter Your First Name"
          errorMessage="Your first name must be at least three characters long."
          value={formData.firstName.value}
          onChange={handleChange}
          valid={formData.firstName.valid}
          autoComplete={formData.firstName.value}
        />
        <FormTextInput
          className="mt-6"
          type="text"
          name="lastName"
          title="Last Name"
          placeholder="Enter Your Last Name"
          errorMessage="Your last name must be at least three characters long."
          value={formData.lastName.value}
          onChange={handleChange}
          valid={formData.lastName.valid}
          autoComplete={formData.lastName.value}
        />
        <FormTextInput
          className="mt-6"
          type="text"
          name="username"
          title="Username"
          placeholder="Enter Your Username"
          errorMessage="Your username must be at least six characters long."
          value={formData.username.value}
          onChange={handleChange}
          valid={formData.username.valid}
          autoComplete={formData.username.value}
        />
        <FormTextInput
          className="mt-6"
          type="text"
          name="email"
          title="Email"
          placeholder="Enter Your Email"
          autoComplete={formData.email.value}
          onChange={handleChange}
          errorMessage="You must input a valid email address!"
          value={formData.email.value}
          valid={formData.email.valid}
        />
        <FormTextInput
          className="mt-6"
          type="date"
          name="dateOfBirth"
          title="Date of Birth"
          onChange={handleChange}
          autoComplete={formData.dateOfBirth.value}
          valid={formData.dateOfBirth.valid}
          errorMessage={"You must be at least 15 to use this site"}
          value={formData.dateOfBirth.value}
        />

        <Button
          className="text-center mt-6"
          type="submit"
          width={6}
          height={3}
          buttonType={"primary"}
          buttonText={"Update"}
        ></Button>
      </form>
    </div>
  );
};

export default MainSettingsForm;
