import { IFormInput } from "./FormInput";
import FormTextInputError from "./FormTextInputError";
import FormTextInputNormal from "./FormTextInputNormal";

export type IFormTextInput = IFormInput;

function FormTextInput(props: IFormTextInput) {
  return (
    <>
      {props.valid ? (
        <FormTextInputNormal {...props} />
      ) : (
        <FormTextInputError {...props} />
      )}
    </>
  );
}

export default FormTextInput;
