import AxiosInstance from "../utils/AxiosInstance";
import dateformat from "dateformat";

const journalIdGet = (date: Date |undefined = undefined) => {
  const axios = AxiosInstance;
  const formattedDate = dateformat(date, "yyyy-mm-dd");
  const url = date? `api/journals/range/?end_date=${formattedDate}&start_date=${formattedDate}` : "api/journals/today/";
  return axios
    .get(url, {})
    .then(function ({ data }) {
      
      if (date && data.length > 0) {
        return {public_id: data[0].public_id, mood: data[0].mood};
      }

      return {public_id: data.public_id, mood: data.mood};
    });
};

export default journalIdGet;
