import { useState, useEffect } from "react";
import PromptsGet from "../api_functions/promptsGet";
import ThoughtPost from "../api_functions/thoughtPost";
import PromptView from "../components/PromptView";
import ThoughtView from "../components/Thought";
import { Prompt } from "../models/prompt";
import { Thought } from "../models/thought";
import ThoughtsGet from "../api_functions/thoughtGet";
import { AUTO_SAVING_JOURNAL_INTERVAL } from "../utils/constants";
import DatePicker from "react-date-picker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../index.css";
import { Mood } from "../models/mood";
import MoodEmoji from "../components/MoodEmoji";
import MoodPost from "../api_functions/moodPost";
import journalIdGet from "../api_functions/journalIdGet";
import ThoughtPut from "../api_functions/thoughtPut";
import MoodsGet from "../api_functions/moodsGet";
import PopupModal from "../components/PopupModal";
import Button from "../components/Button";
import DownloadJournalsPage from "./DownloadJournalsPage";
import MediaPost from "../api_functions/mediaPost";
import ThoughtDelete from "../api_functions/thoughtDelete";
import userProgressionGet from "../api_functions/userProgressionGet";
import MascotLabeled from "../components/MascotLabeled";

function Journal() {
  const [promptList, setPromptList] = useState<Prompt[]>([]);
  const [thoughtList, setThoughtList] = useState<Thought[]>([]);
  const [saveable, setSaveable] = useState<boolean>(true);
  const [showError, setShowError] = useState(false);
  const [showJournalDateError, setShowJournalDateError] = useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [journalId, setJournalId] = useState<string>("");
  const [showDownloadPage, setShowDownloadPage] = useState(false);
  const [currentMood, setCurrentMood] = useState<Mood>({
    mood_type: "neutral",
    img_string: "neutral.svg",
  });
  const [moodList, setMoodList] = useState<Mood[]>([]);
  const toggleError = () => {
    setShowError(!showError);
  }
  const toggleDateError = () => {
    setShowJournalDateError(!showJournalDateError);;
  };
  const promptClickHandler = (prompt: Prompt) => {
    if (saveable) {
      ThoughtPost(
        journalId,
        { prompt: prompt.public_id, public_id: "", text: "", media: "" },
        postThoughtHandler
      );
    } else {
      toggleError();
    }
  };

  const streak = userProgressionGet()

  const updateThoughts = async (date: Date) => {
    const journalData = await journalIdGet(date);
    if (!journalData.public_id) {
      setThoughtList([]);
      return;
    }
    setJournalId(journalData.public_id);
    
    const f = async () => {
      setPromptList(await PromptsGet(journalData.public_id));
      setThoughtList(await ThoughtsGet(journalData.public_id));
      setMoodList(await MoodsGet());
    };

    f();
  
    if (journalData.mood !== undefined && journalData.mood !== null){
      setUserMood(journalData.mood);
    }
  };

  const toggleDownloadPage = () => {
    setShowDownloadPage(!showDownloadPage);
  };

  const onChangeDatePicker = (selectedDate: Date) => {
    if (selectedDate.getTime() > Date.now()) {
      setShowJournalDateError(true);
      return;
    }
    updateJournal();
    setDate(selectedDate);
    setSaveable(selectedDate.getDate() === new Date().getDate());
    updateThoughts(selectedDate);
  };

  const postThoughtHandler = (thought: Thought) => {
    setThoughtList((ps) => [...ps, thought]);
  };

  const saveThoughtHandler = (thought: Thought) => {
    setThoughtList((ps) =>
      ps.map((t) => {
        if (t.public_id === thought.public_id) {
          return thought;
        }
        return t;
      })
    );
  };

  const saveMediaHandler = (media: string | null, thought: Thought) => {
    MediaPost(journalId, media, thought);
  };

  const deleteThoughtHandler = (thought: Thought) => {
    ThoughtDelete(journalId, thought);
    setThoughtList(
      thoughtList.filter((t) => t.public_id !== thought.public_id)
    );
  };

  const saveJournal = async () => {
    Promise.all(thoughtList.map(async (t) => await ThoughtPut(journalId, t)));
  };

  const updateJournal = () => {
    const f = async () => {
      await saveJournal();
      MoodPost(currentMood, journalId);
    };
    f();
  };

  useEffect(() => {
    const f = async () => {
      const journalData = await journalIdGet();
      setJournalId(journalData.public_id);
      if (journalData.mood !== null) {
        setUserMood(journalData.mood);
      }
    };
    f();
  }, []);

  useEffect(() => {
    const f = async () => {
      setPromptList(await PromptsGet(journalId));
      setThoughtList(await ThoughtsGet(journalId));
      setMoodList(await MoodsGet());
    };

    f();
  }, [journalId]);

  useEffect(() => {
    if (saveable) {
      const autoSave = setInterval(() => {
        updateJournal();
      }, AUTO_SAVING_JOURNAL_INTERVAL);

      return () => clearInterval(autoSave);
    }
  }, [thoughtList, saveable, currentMood, journalId]);

  const setUserMood = (givenCurrentMood: Mood) => {
    setCurrentMood(givenCurrentMood);
  };

  return (
    <>
      <PopupModal
        isOpen={showError}
        onClose={toggleError}
        children={<p className="mt-8 solitreo text-xl">You can only add prompts for the current day!</p>}
        showMascot={true}
        mascotHeight={140}
        mascotWidth={140}
      />
      <PopupModal isOpen={showJournalDateError}
        onClose = {toggleDateError}
        children={<p className="mt-8 solitreo text-xl">You can only browse past journals!</p>}
        showMascot={true}
        mascotHeight={140}
        mascotWidth={140}
        />

      <div className="flex border-solid justify-center p-1 m-1 rounded border border-orange-900">
        <p className="mr-3 retroText">Select Journaling Date:</p>
        <DatePicker onChange={onChangeDatePicker} value={date} />
      </div>
      <div className="flex border-solid justify-center m-1 p-1 rounded border promptContainer">
        {promptList.map((e) => (
          <PromptView onClickHandler={promptClickHandler} prompt={e} />
        ))}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex justify-center py-2">
        <div className="border-solid mt-6 p-[2%] relative rounded-md w-2/4 border-4 min-h-[500px] thoughtContainer">
          
          {thoughtList.length == 0 && !saveable && <MascotLabeled label="Hey, Sorry, but you I don't remember you have journald this day! no worries, you can journal today!"/>}

          {thoughtList.length == 0 && saveable && <MascotLabeled label="Hey, it appears that you did not journal today, start now!!!"/>}

          {thoughtList.map((t) => (
            <div key={t.public_id}>
              <ThoughtView
                thought={t}
                prompt={promptList.find((p) => p.public_id === t.prompt)!}
                editable={saveable}
                updateMedia={saveMediaHandler}
                deleteThoughtHandler={deleteThoughtHandler}
                saveThought={saveThoughtHandler}
              />
            </div>
          ))}
        </div>
      </div>
      {thoughtList.length > 0 && saveable && (
        <>
          <nav className="flex justify-center rounded-md py-2 bg-calm-pink space-x-4">
            <h3 className="retroText px-4 py-6">How do you feel:</h3>
            {moodList.map((m) => (
              <MoodEmoji currentMood={m} setCurrentMood={setUserMood} />
            ))}
            <h3 className="retroText px-4 py-6">
              Current mood selected: {currentMood.mood_type}
            </h3>
          </nav>
          <div className="mt-2 flex justify-center">
            <Button
              onClick={() => updateJournal()}
              width={0}
              height={0}
              buttonType={"primary"}
              buttonText={"Save"}
            />
            <div className="w-2"></div>
            <Button
              width={0}
              onClick={toggleDownloadPage}
              height={0}
              buttonType={"primary"}
              buttonText={"Download Journals"}
            />
          </div>
          <PopupModal
            isOpen={showDownloadPage}
            onClose={toggleDownloadPage}
            children={<DownloadJournalsPage />}
          />
        </>
      )}
      ;
    </>
  );
}

export default Journal;
