import Progression from "../models/progression";
import AxiosInstance from "../utils/AxiosInstance";

export default async function userProgressionGet(): Promise<Progression> {
  const axios = AxiosInstance;

  return (
    axios
      .get('/api/progression')
      .then(function ({ data }) {
        return data;
      })
  )
}