import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import Button from "./Button";

export default function CookiesBanner() {
  const [madeChoice, setMadeChoice] = useState(false);
  const handleAcceptCookies = (event: MouseEvent<HTMLButtonElement>) => {
    localStorage.setItem("cookies-accepted", "yes");
    setMadeChoice(true);
  };
  return (
    <div>
      {localStorage.getItem("cookies-accepted") === "yes" || madeChoice || (
        <div className="absolute inset-x-0 bottom-0 flex flex-col justify-between gap-y-4 text-center bg-orange-100 gap-x-8  p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">
          <p className="max-w-4xl retroText text-sm leading-6 text-gray-900">
            This website uses only absolutely essential cookes in order to
            function. You can read more about them{" "}
            <Link
              className="text-blue-700"
              to="cookiespolicy"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </Link>
            .
          </p>
          <div className="flex flex-none items-center gap-x-5">
            <Button
              onClick={handleAcceptCookies}
              width={2}
              height={1}
              buttonType={"primary"}
              buttonText={"Got it!"}
            ></Button>
          </div>
        </div>
      )}
    </div>
  );
}
