// gets the image type from a base64 string
export function getImageType(base64: string) {
    const first_char = base64.charAt(0);
    const map: { [key: string]: string; } = {
        "/": "jpeg",
        "i": "png",
        "R": "gif",
        "U": "webp",
        "P": "svg+xml",
        "S": "tiff",
        "T": "tiff",
        "A": "heic",
    };
    // assert that first_char is a key in map
    if (!(first_char in map)) {
        throw new Error("Invalid base64 string");
    }
    // return the value associated with the key
    return map[first_char];
}
