import AxiosInstance from "../utils/AxiosInstance";
import { Mood } from "../models/mood";

export default async function MoodsGet(): Promise<Mood[]> {
  const axios = AxiosInstance;

  return axios
    .get(`api/moods/`, {})
    .then(function ({ data }) {
      return data;
    });
}
