import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../utils/useAuth";
import { AiFillHome } from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import Button from "./Button";
import StreakView from "./StreakWidget";

const Navbar = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    setLoggedIn(auth?.isAuthenticated!);
  }, [auth?.isAuthenticated]);

  const handleLogin = () => {
    navigate("/login");
  };
  const handleSignup = () => {
    navigate("/signup");
  };
  const handleSignout = () => {
    auth?.signOut();
    navigate("/");
  };
  return (
    <div>
      <nav className="flex p-3 bg-blue-300 rounded-br-md">
        <button onClick={() => navigate("")}>
          <IconContext.Provider value={{ className: "text-yellow-100 ml-8" }}>
            <div>
              <AiFillHome size={60} href="/" />
            </div>
          </IconContext.Provider>
        </button>
        <div className="w-1/2"></div>
        <div className="flex ">
          {loggedIn && <StreakView/>}
          {loggedIn || (
            <Button
              className="m-2"
              width={6}
              height={3}
              buttonType={"primary"}
              buttonText={"Sign Up!"}
              onClick={handleSignup}
            />
          )}
          {loggedIn || (
            <Button
              className="m-2"
              width={6}
              height={3}
              buttonType={"secondary"}
              buttonText={"Log in"}
              onClick={handleLogin}
            />
          )}
          {loggedIn && (
            <Button
              className="m-2"
              width={6}
              height={3}
              buttonType={"secondary"}
              buttonText={"Sign Out"}
              onClick={handleSignout}
            />
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
