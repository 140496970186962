import AxiosInstance from "../utils/AxiosInstance";
import { Thought } from "../models/thought";

export default async function ThoughtsGet(journal_id: string): Promise<Thought[]> {
  const axios = AxiosInstance;

  return axios
    .get(`api/journals/${journal_id}/thoughts/`, {})
    .then(function ({ data }) {
      return data;
    });
}
