import { Prompt } from "../models/prompt";
import AxiosInstance from "../utils/AxiosInstance";

export default async function SendVerifyEmail() {
  const axios = AxiosInstance;

  return axios
    .get(`api/sendverifyemail`, {})
    .then(function ({ data }) {
      return data;
    });
}
