import { Outlet } from "react-router";
import CookiesBanner from "./CookiesBanner";
import HomeFooter from "./HomeFooter";
import Navbar from "./Navbar";

const Base = () => {
  return (
    <div>
      <Navbar />
      <Outlet />
      <HomeFooter />
      <CookiesBanner />
    </div>
  );
};

export default Base;
